<template>
  <div>
    <h1 class="mb-0">Recuperar Contraseña</h1>
    <p>Ingrese Su Nueva Contraseña</p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)" class="mt-4">
        <b-row>
          <b-form-group
            class="col-md-12"
            label="Contraseña:"
            label-for="Contraseña"
          >
            <ValidationProvider
              name="Contraseña"
              rules="required|confirmed:password_confirmation"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="form.password"
                type="password"
                placeholder="Contraseña"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group
            class="col-md-12"
            label="Confirmar contraseña:"
            label-for="Confirmar contraseña"
          >
            <ValidationProvider
              vid="password_confirmation"
              name="Confirmar contraseña"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="form.password_confirmation"
                type="password"
                placeholder="Confirmar contraseña"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
        </b-row>
        <div class="d-inline-block w-100 mt-4">
          <button type="submit" class="btn btn-primary float-right">
            Guardar Contraseña
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import csrf from '@/apis/Csrf'
import axios from 'axios'
import { core } from '@/config/pluginInit'
export default {
  name: 'RecoverPassword1',
  data () {
    return {
      form: {
        password: '',
        password_confirmation: '',
        token: this.$route.query.token,
        email: this.$route.query.email
      }
    }
  },
  methods: {
    async onSubmit () {
      try {
        this.$store.commit('run')
        await csrf.getCookie()
        await axios.post('/api/password/reset', this.form)
        this.$router.push('/')
        this.$store.commit('stop')
        core.showSnackbar('success', 'Contraseña Actualizada')
      } catch (error) {
        console.log(error)
        this.$store.commit('stop')
        const errors = error.response.data.errors
        for (const key in errors) {
          core.showSnackbar('error', errors[key][0])
          return false
        }
      }
    }
  }
}
</script>
